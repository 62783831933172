import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { DE, GLOBAL } from '../../constants/languageCode';
import { getSiteUrl } from '../../utils/getSiteUrl';
import { prepareHrefLang } from '../../utils/prepareHrefLang';
import { prepareLinkDomainLanguageAlias } from '../../utils/prepareLinkDomainLanguageAlias';

interface LanguageInterface {
    language: string;
    url: string;
    type?: string;
}

interface AlternateInterface {
    languages: Array<LanguageInterface>;
}

const Alternate: FC<AlternateInterface> = ({ languages }) => {
    const defaultLanguage = languages?.filter((langauge: LanguageInterface) => langauge.language === DE);
    const siteUrl = getSiteUrl();

    const displayAlternateLinks = (): ReactNode =>
        languages
            ?.filter((language: LanguageInterface) => language.language && language.url)
            ?.filter((language: LanguageInterface) => language.language !== DE && language.language !== GLOBAL)
            ?.map((language: LanguageInterface, index: number) => (
                <link
                    rel="alternate"
                    href={prepareLinkDomainLanguageAlias(siteUrl, language.language, language.url)}
                    hrefLang={prepareHrefLang(language.language)}
                    key={language.url + index}
                />
            ));

    return (
        <React.Fragment>
            <Helmet>
                {displayAlternateLinks()}
                {defaultLanguage && defaultLanguage.length > 0 && defaultLanguage[0] && defaultLanguage[0].url && (
                    <link
                        rel="alternate"
                        href={prepareLinkDomainLanguageAlias(siteUrl, DE, defaultLanguage[0]?.url)}
                        hrefLang="x-default"
                    />
                )}
            </Helmet>
        </React.Fragment>
    );
};

export default Alternate;
