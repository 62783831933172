import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC, ReactNode, useState } from 'react';
import { urlForImage } from '../../utils/urlForImage';
import YoutubePopup from '../YoutubePopup/YoutubePopup';
import { AnimatedBanner, Container, HeaderItem, HeaderItems, HeaderLink, TitleBox } from './HeaderStyle';
import VideoButton from './VideoButton/VideoButton';

interface Category {
    name: string;
    url: string;
}

interface HeaderProps {
    category?: Category;
    subCategory?: Category;
    item?: string;
    categoryItem?: string;
    banner_image?: string;
    banner_title?: string;
    isBanner?: boolean;
    color?: string | null;
    second_banner?: string;
    animated?: boolean | null;
    youtube?: string | null;
}

const Header: FC<HeaderProps> = ({
    category,
    subCategory,
    item,
    categoryItem,
    banner_image,
    banner_title,
    isBanner,
    color,
    second_banner,
    animated,
    youtube,
}) => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const [isYoutubeActive, setIsYoutubeActive] = useState(false);

    const displayCategories = (): ReactNode => (
        <>
            <HeaderItem>
                <HeaderLink to={`/${language}/`}>{t('home')}</HeaderLink>
            </HeaderItem>
            {category && category.name && category.url && (
                <HeaderItem>
                    <HeaderLink to={category.url}>{category.name}</HeaderLink>
                </HeaderItem>
            )}
            {subCategory && subCategory.name && subCategory.url && (
                <HeaderItem>
                    <HeaderLink to={subCategory.url}>{subCategory.name}</HeaderLink>
                </HeaderItem>
            )}
            {categoryItem && <HeaderItem>{categoryItem}</HeaderItem>}
            {item && <HeaderItem>{item}</HeaderItem>}
        </>
    );

    return isBanner ? (
        <Container isBanner={isBanner} imgUrl={urlForImage(banner_image)} color={color}>
            <HeaderItems isBanner={isBanner}>{displayCategories()}</HeaderItems>
            {banner_title && (
                <TitleBox isYoutube={!!youtube}>
                    <p>{banner_title}</p>
                </TitleBox>
            )}
            {youtube && <VideoButton setIsYoutubeActive={setIsYoutubeActive} />}
            {isYoutubeActive && <YoutubePopup setIsActive={setIsYoutubeActive} link={youtube} />}
            {second_banner && animated && <AnimatedBanner imgUrl={urlForImage(second_banner)} />}
        </Container>
    ) : (
        <Container>
            <HeaderItems>{displayCategories()}</HeaderItems>
        </Container>
    );
};

export default Header;
